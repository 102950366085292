<template>
  <div class="px-4 pt-1 lg:pb-[160px]">
    <div
      class="container mx-auto flex flex-col gap-[72px] overflow-hidden rounded-[20px] bg-gradient-to-b from-yellow to-white p-6 shadow-base lg:gap-14 lg:p-10"
    >
      <div class="flex flex-col gap-6 lg:flex-row">
        <div
          v-for="(stat, index) in stats"
          :key="index"
          class="relative flex w-full flex-col overflow-hidden rounded-xl shadow-md"
        >
          <LandingPageOurImpactCardCounter v-bind="stat" />
        </div>
      </div>

      <div class="flex flex-col justify-between gap-8 lg:flex-row">
        <div class="flex basis-full flex-col gap-6">
          <span
            class="text-shadow mb-[22px] font-Kontesa text-h2-mobile font-extrabold text-white md:mb-4 md:text-h2"
          >
            Our impact
          </span>

          <p
            class="font-Kontesa text-h3-mobile font-medium text-navy lg:text-h4 lg:font-bold"
          >
            Ocean-bound plastic<br class="md:hidden" />
            removed
          </p>

          <p class="max-w-[44ch] text-p1 font-medium text-navy">
            Check out all the good stuff our members are collectively doing!
            <br />
            <br />
            We’ve already planted nearly one million trees, removed tons of
            ocean plastic, and alleviated hunger and poverty around the world.
            But we’re just gettin’ started!
          </p>

          <div class="mt-10 md:w-fit">
            <Button variant="primary" class="w-full" @click="redirect"
              >join with $1/mo now</Button
            >
          </div>
        </div>

        <div
          v-motion-slide-visible-left
          class="flex basis-full rounded-[15px] border border-navy bg-white p-[6px] shadow-[3px_3px_0px_#16114F] md:p-3 md:shadow-[6px_6px_0px_#16114F]"
        >
          <div
            class="relative w-full overflow-hidden rounded-xl border border-navy bg-navy shadow-[1px_1px_0px_#16114F] md:shadow-[2px_2px_0px_#16114F]"
          >
            <NuxtImg
              src="/images/landing-page/ocean-plastic.png"
              alt="ocean plastics"
              sizes="90vw md:60vw"
              class="h-full w-full object-cover"
            />

            <div
              class="absolute inset-0 flex flex-col items-center justify-center gap-4 bg-black/20"
            >
              <div
                class="font-Kontesa text-h1-mobile font-semibold text-white md:text-h2"
              >
                {{
                  plasticFischerImpact
                    ?.toFixed(0)
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                lbs
              </div>

              <div
                class="text-nayv rounded-md border border-navy bg-ocean-low p-2 text-p2 font-bold uppercase"
              >
                ocean plastic removed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { campaignQueries } from "~/domains/campaigns/queries";
import { Campaign } from "~/lib/campaigns";

const { Routes } = useRouteUtils();
const router = useRouter();
const redirect = () =>
  router.push({
    path: Routes.SIGN_UP,
    query: {
      campaignId: Campaign.PF,
      amount: 10,
    },
  });

const { data: metrics, suspense: suspenseMetrics } = useQuery({
  ...campaignQueries.metricsSummary(),
  suspense: true,
});

const loadCampaignMetrics = async () => {
  return Promise.all([suspenseMetrics()]).catch((e) => {
    console.error(e);
  });
};

const plasticFischerImpact = computed(() => {
  return metrics.value?.find((m) => m.campaignId === Campaign.PF)?.impact ?? 0;
});

await loadCampaignMetrics();

const stats = computed(() => {
  const plasticFischer = metrics.value?.find(
    (m) => m.campaignId === Campaign.PF,
  );
  const tftf = metrics.value?.find((m) => m.campaignId === Campaign.TFTF);
  const ovi = metrics.value?.find((m) => m.campaignId === Campaign.OVI);

  return [
    {
      campaignId: Campaign.PF,
      value: plasticFischer?.impact ?? 0,
      unit: "lbs",
      desc: "Ocean-bound plastic removed",
      icon: "turtle",
      lastMonthImpact: plasticFischer?.lastMonthImpact ?? 0,
    },
    {
      campaignId: Campaign.TFTF,
      value: tftf?.impact ?? 0,
      unit: null,
      desc: "Trees Planted",
      icon: "tree",
      lastMonthImpact: tftf?.lastMonthImpact ?? 0,
    },
    {
      campaignId: Campaign.OVI,
      value: ovi?.impact ?? 0,
      unit: "lbs",
      desc: "Ocean Plastic removed",
      icon: "soda",
      lastMonthImpact: ovi?.lastMonthImpact ?? 0,
    },
  ];
});
</script>
